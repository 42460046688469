// 点位 数据
const cameraPointData = {
  农光互补: {
    cx: 15.119238641456931,
    cy: 179.9898704928499,
    cz: 23.613071209303207,
    tx: -75.7370604283668,
    ty: 102.70178887512415,
    tz: -11.039934814652419,
  },
  远处风景: {
    cx: 29.102480686276152,
    cy: 259.06066186434316,
    cz: 160.77775763888374,
    tx: -155.2289314267918,
    ty: 123.31301569907436,
    tz: 139.89636540640376,
  },
  整县推进: {
    cx: 217.04552762398805,
    cy: 118.97395261626663,
    cz: 128.3323027196054,
    tx: -50.22832963279888,
    ty: -23.294743301327248,
    tz: 50.141693715395725,
  },
  云: {
    cx: 175.32143649809035,
    cy: 155.30438439874789,
    cz: -73.30701395348183,
    tx: -21.994307551258803,
    ty: 30.104312,
    tz: 28.019043971647985,
  },
  综合能源智慧园区: {
    cx: 352.3610043077248,
    cy: 189.9199096069467,
    cz: 16.03808239676519,
    tx: 53.73377731683437,
    ty: -44.382935034546946,
    tz: -122.70708400306108,
  },
  CBD高楼: {
    cx: 204.8333492802853,
    cy: 187.9162404782283,
    cz: -11.031142420615865,
    tx: -13.107453623141533,
    ty: 36.73743485320216,
    tz: -107.45161735713087,
  },
  水面光伏: {
    cx: 357.97308906344995,
    cy: 94.34499857788231,
    cz: 40.453215422893265,
    tx: 96.89791756699512,
    ty: -100.87551086322529,
    tz: -76.97131515151924,
  },
  星聚虚拟电厂: {
    cx: 305.35457329238005,
    cy: 96.77398852725482,
    cz: 152.76437594040252,
    tx: 33.41558225290776,
    ty: -79.01664974622746,
    tz: 29.44913151861363,
  },
  // "3060双碳": {
  //   cx: 341.1009731091692,
  //   cy: 94.83657870167852,
  //   cz: 53.1249256057518,
  //   tx: 92.15023318922829,
  //   ty: -33.47037858772683,
  //   tz: -150.88651197415055,
  // },
};

const spriteNameArray = [
  "WE产品家族点位",
  "CBD商业区点位",
  "综合能源智慧园区点位",
  "虚拟电厂点位",
  "整县推进点位",
  // "双碳平台点位",
];

export { cameraPointData, spriteNameArray };
