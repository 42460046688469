<style lang="less" scoped>
.control {
  width: 576px;
  height: 916px;
  margin: 64px 48px;
  .top-control {
    width: 576px;
    height: 204px;
    background-image: url("../../assets/top/power.png");
    background-size: 100%;
  }
  .bot-control {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 62px;
    .left {
      width: 253px;
      height: 210px;
      background-image: url("../../assets/top/bot-con.png");
      background-size: 100%;
    }
    .right {
      margin-left: 40px;
      width: 194px;
      height: 98px;
      background-image: url("../../assets/top/bot-con1.png");
      background-size: 100%;
    }
  }
}
</style>
<template>
  <custom-box :boxWidth="672" :boxHeight="580">
    <div class="control">
      <div class="top-control"></div>
      <div class="bot-control">
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
  </custom-box>
</template>
<script>
export default {};
</script>
