<template>
  <custom-box-right>
    <img style="width:100%" v-if="isCn" :src="'./assets/images/pcs.png'" alt="">
    <img style="width:100%" v-else :src="'./assets/images/pcs-en.png'" alt="">
  </custom-box-right>
</template>

<script>

export default{
}
</script>