// const console = {
//   log: () => { }
// }

import { Utils } from "run-scene-v2";
import bus from "./../utils/bus";
import { cameraPointData } from "./const";
import * as THREE from "three";

// 声明变量
let camera, scene, controls, renderer2, renderer, dom, t, p, runScene, Bus;
// 工具
const { getMacro } = Utils;

// 拿资源
const setAssets = (assets) => {
  camera = assets.camera;
  scene = assets.scene;
  controls = assets.controls;
  renderer = assets.renderer;
  dom = assets.engineDom;
  t = assets.t;
};

//  页面接口总出口
function Change(runScene, onDone) {
  setAssets({ ...runScene.assetsEx.get(), t: this, runScene });

  t.runScene = runScene;

  t.onDone = onDone;

  // 场景事件
  this.events = null;
  // 工具
  this.tool = null;

  // 最后一帧加载回调
  t.runScene.on("loaded", async () => {

    this.events = new Events();

    this.tool = new Tool();

    this.cameraPoint = new CameraPoint();

    this.cameraPoint.init();

    this.sprite = new Sprite();

    this.sprite.init();

    this.anima = new Anima();

    this.anima.init();

    // 基本配置
    (() => {
      controls.mouseButtons = {
        PAN: THREE.MOUSE.LEFT,
        ZOOM: THREE.MOUSE.MIDDLE,
        ORBIT: THREE.MOUSE.RIGHT,
      };

      // 场景的基本配置
      controls.maxPolarAngle = Math.PI / 2 - 0.3;

      // 入场动画
      t.cameraPoint.reset();

      controls.minDistance = 120;

      controls.maxDistance = 1000;

      controls.screenSpacePanning = false;

      t.runScene.script.playAll();

      t.runScene.assetsEx.controls.autoRotate = false


      const resizeCanvas = function (x) {

        let map = t.runScene.assetsEx.engineDom.getBoundingClientRect();
        t.runScene.setSize(map.width, map.height);


      };

      // resizeCanvas()
      bus.emit("resize", resizeCanvas);
      bus.on("changeScene", (x) => {
        setTimeout(() => {
          resizeCanvas(x);
        }, 0);
      });


      // 加载回调
      t.onDone();
    })();
  });

  // 销毁
  this.dispose = () => {
    runScene.dispose()
  }
}

// 精灵图
class Sprite {
  // 精灵图 表
  spriteModelMap = {};

  init() {
    // 获取 点位 映射表
    this.getSpriteModelMap();

    // 添加dom
    this.addDom();

    // 显示 或 隐藏 对应的dom接口
    bus.on("three-scene-1-isShowSprite", this.isShowSprite.bind(this));

    // 显示 隐藏 接口 一次性全部接口
    bus.on("three-scene-1-isShowAllSprite", this.isShowAllSprite.bind(this));

  }
  // 获取 点位 映射表
  getSpriteModelMap() {
    let a = [];
    const dianwei = t.runScene.modelEx.getModel("dianwei");
    dianwei.children.map((childModel) => {
      const name = childModel.name;
      this.spriteModelMap[name] = {};
      this.spriteModelMap[name]["model"] = {};
      this.spriteModelMap[name]["model"] = childModel;
      a.push(name);
    });
  }

  // 添加dom
  addDom() {
    let { domTo2Dui, getMacro } = Utils;
    Object.keys(this.spriteModelMap).map((name) => {
      let btnDom = document.querySelector(`.${name}-sprite-btn`);
      this.spriteModelMap[name]["childModel-btn"] = domTo2Dui(btnDom);
      this.spriteModelMap[name][
        "childModel-btn"
      ].name = `name-sprite-${name}-btn`;
      this.spriteModelMap[name]["model"].add(
        this.spriteModelMap[name]["childModel-btn"]
      );
      getMacro(() => {
        btnDom.classList.add("show");
      }, 500);

      let imgDom = document.querySelector(`.${name}-sprite-img`);
      this.spriteModelMap[name]["childModel-img"] = domTo2Dui(imgDom);
      this.spriteModelMap[name][
        "childModel-img"
      ].name = `name-sprite-${name}-img`;
      this.spriteModelMap[name]["model"].add(
        this.spriteModelMap[name]["childModel-img"]
      );
      this.spriteModelMap[name]["childModel-img"].visible = false;
      getMacro(() => {
        imgDom.classList.add("show");
      }, 500);
    });
  }

  // 是否 显示精灵图
  isShowSprite(name, isShow) {
    const modelBtn = this.spriteModelMap[name]["childModel-btn"];
    const modelImg = this.spriteModelMap[name]["childModel-img"];
    if (!modelBtn || !modelImg) return;
    modelImg.visible = isShow;
    modelBtn.visible = !isShow;
    console.log(name, this.spriteModelMap[name], '显示与否');
  }

  // 一次性显示 和 关闭 所有标签
  isShowAllSprite(isShow) {
    Object.keys(this.spriteModelMap).map((name) => {
      const modelBtn = this.spriteModelMap[name]["childModel-btn"];
      const modelImg = this.spriteModelMap[name]["childModel-img"];
      if (!modelBtn || !modelImg) return;
      modelImg.visible = isShow;
      modelBtn.visible = !isShow;
      console.log(name, this.spriteModelMap[name], 'modelBtn');
    })
  }
}

// 动画
class Anima {

  init() {
    this.runAnima()
  }

  runAnima() {
    //播放动画
    t.runScene.anima.play(
      t.runScene.modelEx.getModel("car动画"),
      "Take 001",
      {
        loop: true,
      }
    );
    t.runScene.anima.play(t.runScene.modelEx.getModel("船动画"), "Take 001", {
      loop: true,
    });
    t.runScene.anima.play(
      t.runScene.modelEx.getModel("风车动画"),
      "Take 001",
      {
        loop: true,
      }
    );

    t.runScene.anima.play(
      t.runScene.modelEx.getModel("cloud"),
      "Take 001",
      {
        loop: true,
      }
    );

    t.runScene.timeLineEx.playGroup("组1").then(() => {
    });
  }
}

// 相机 点位
class CameraPoint {
  // 点位 数据
  pointData = cameraPointData;

  init() {
    //  注册 聚焦 事件
    bus.on("scene-1-cameraAnima", this.cameraAnima.bind(this));
    // 重置视角
    bus.on("scene-1-reset", this.reset.bind(this));
  }

  // 相机 动画
  cameraAnima(name, time = 1) {
    t.events.going = false;
    t.events.controlStart();
    if (!this.pointData[name]) return;
    const position = this.pointData[name];
    t.events.cameraAnima(position, time);
  }

  // 重置视角
  reset() {
    t.events.cameraAnima(
      {
        cx: 518.5114820511892,
        cy: 275.32297030149806,
        cz: 65.63519853593208,
        tx: 4.777016857416947,
        ty: -47.036829284337344,
        tz: -34.534830331753156,
      },
      2
    );
  }
}

// 工具方法 ( 可封的公共方法 )
class Tool {
  // 相机 动画
  cameraAnima(position, time = 1, fn = () => { }) {
    t.events.closeAnimaAtStart.cameraAnima = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      },
      () => {
        fn && fn();
      }
    );
  }
  // 模型 透明度 渐变动画
  showAnima(info) {
    const { model, isShow, time, cb, opacity } = info;
    const models = [];
    model.traverse((m) => {
      if (m.type === "Group") return;
      if (m.type === "Object3D") return;
      m.material.transparent = true;
      isShow ? (m.material.opacity = 0) : null;
      models.push(m);
    });
    if (isShow) model.visible = isShow;
    Utils.anima(
      { opc: isShow ? 0 : opacity || 1 },
      { opc: isShow ? opacity || 1 : 0 },
      time,
      (data) => {
        models.map((m) => (m.material.opacity = data.opc));
      },
      () => {
        if (!isShow) model.visible = isShow;
        cb && cb();
      }
    );
  }
}

// 基本事件
class Events {
  downPosition = { x: 0, y: 0 };
  closeAnimaAtStart = { enterAnima: "", cameraAnima: "" };
  constructor() {
    t.runScene.assetsEx.controls.addEventListener("start", this.controlStart);
    t.runScene.cb.model.setSelect.add(
      "trigger-click",
      this.triggerClick.bind(this)
    );
    t.runScene.cb.events.pointer.down.add("trigger", (e) =>
      t.runScene.modelEx.selectNull()
    );

    t.runScene.cb.render.add("renderer", () => {
      this.stopMove();
    });
  }

  cameraAnima(position, time = 1) {
    this.controlStart();
    this.closeAnimaAtStart.cameraAnimaFn = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      }
    );
  }

  triggerClick = (model) => {
    if (!model) return;
    const log = `cx:${camera.position.x}, cy:${camera.position.y}, cz:${camera.position.z}, tx:${controls.target.x}, ty:${controls.target.y}, tz:${controls.target.z} `;
    console.log("点位:", log);
    bus.emit("logClickModel-1", model);
  };

  // 是否 处于动画中
  going = true;
  // 相机停止移动
  stopMove = () => {
    let dis = camera.position.distanceTo({
      x: 0,
      y: 0,
      z: 0,
    });
    if (dis >= 600) {
      controls.enablePan = false;
      if (!this.going) return;
      this.going = false;
      // 1.5秒后恢复
      getMacro(() => {
        t.events.controlStart();
        // 重新恢复至初始视角动静
        t.cameraPoint.reset();
      }, 1500);
    } else {
      // 可以进行右键移动
      controls.enablePan = true;
      this.going = true;
    }
  };

  // 清除动画
  controlStart = () => {
    this.closeAnmia();
  };

  // 暂停动画 并清空内容 item就是那个动画
  closeAnmia() {
    Object.values(this.closeAnimaAtStart).map((i) => i && i.kill());
  }

  dispose() {
    controls.removeEventListener("start", this.controlStart);
  }
}

export default Change;
