<template>
  <div class="three-scene-1" ref="three-scene-1" onselectstart="return false;">
    <!-- <div @pointerdown="(e) => e.stopPropagation()" class="btn">
      <button
        @click="
          () => {
            cameraAnima(point);
          }
        "
        v-for="(_, point) in cameraPointData"
      >
        {{ point }}
      </button>

      <button>
        <router-link to="scene-2">跳转综合能源智慧园区</router-link>
      </button>
      <button>
        <router-link to="scene-3">跳转整县推进</router-link>
      </button>

      <button @click="restPosition">重置视角</button>
    </div> -->
    <div
      :class="'sprite-name-btn ' + value.name + '-sprite-btn '"
      v-for="(value, key, index) in pointMap"
      :key="index"
      v-show="value.isShow"
    >
      <div class="icon-bg">
        <div class="icon">
          <img :src="value.imgSrc" alt="" />
        </div>
      </div>
    </div>

    <div
      :class="'sprite-name-img ' + value.name + '-sprite-img'"
      v-for="(value, key, index) in pointMap"
      :key="index"
      v-show="value.isShow"
    >
      <div class="left-img">
        <div class="icon">
          <img :src="value.imgSrc" alt="" />
        </div>
      </div>
      <div class="text" id="animae-width">
        {{ isCn ? value.uiName : value.enName }}
      </div>
    </div>
  </div>
</template>

<script>
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Change from "./Change";
import { RunScene } from "run-scene-v2";
import bus from "./../utils/bus";
import { cameraPointData, spriteNameArray } from "./const";
export default {
  data() {
    return {
      cameraPointData: cameraPointData,
      spriteNameArray: spriteNameArray,
      pointMap: {
        云: {
          isShow: false,
          name: "WE产品家族点位",
          uiName: "WE产品家族",
          enName: "WE Product Family",
          imgSrc: require("@/assets/common/upload.png"),
        },
        CBD高楼: {
          isShow: false,
          name: "CBD商业区点位",
          uiName: "建筑光伏一体化",
          enName: "Building Integrated PV",
          imgSrc: require("@/assets/common/cbd.png"),
        },
        综合能源智慧园区: {
          isShow: false,
          name: "综合能源智慧园区点位",
          uiName: "综合能源智慧园区",
          enName: "Integrated Energy Smart Park",
          imgSrc: require("@/assets/common/yuanqu.png"),
        },
        星聚虚拟电厂: {
          isShow: true,
          name: "虚拟电厂点位",
          uiName: "虚拟电厂",
          enName: "Virtual Power Plant",
          imgSrc: require("@/assets/common/ele.png"),
        },
        整县推进: {
          isShow: false,
          name: "整县推进点位",
          uiName: "整县推进",
          enName: "County-wide Promotion",
          imgSrc: require("@/assets/common/zhengxian.png"),
        },
        // "3060双碳": {
        //   isShow: false,
        //   name: "双碳平台点位",
        //   uiName: "双碳平台",
        //   imgSrc: require("@/assets/common/co2.png"),
        // },
      },
    };
  },
  activated() {
    this.$refs["three-scene-1"].style.display = "block";
  },
  deactivated() {
    this.$refs["three-scene-1"].style.display = "none";
  },
  mounted() {
    document.getElementById("app").appendChild(this.$refs["three-scene-1"]);

    // 加载场景
    this.loadScene();
    // 打印点击的模型接口
    bus.on("logClickModel-1", this.logClickModel);
  },
  methods: {
    showItem() {},
    // 加载场景
    loadScene() {
      scene = new RunScene({
        msg: {
          // show: true,
        },
        // showFps: true,
        coverSameId: true,
        instanceClone: false,
        selectIndex: null,
        render3: true,
        render2: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
        },
      }).load({
        // path: "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202212120928105448901001202270",
        path: process.env.VUE_APP_BASE_ASSETS + "assets/scene1.glb",
        // path: "https://test2-1303915342.cos.ap-shanghai.myqcloud.com/goodwe/scene1.glb",
        dom: this.$refs["three-scene-1"],
      });

      // 实例化场景
      sceneChange = new Change(scene, this.onDone);
      window.sceneChange1 = scene;
    },
    // 加载完回调
    onDone() {
      console.log("场景加载完毕~");
      this.$emit("onDone");
      /**
       * Interface description: 显示 或 隐藏 对应的dom接口
       *          params1 <string>  "WE产品家族点位", "CBD商业区点位", "综合能源智慧园区点位", "虚拟电厂点位", "整县推进点位", "双碳平台点位"
       *          params2 <boolean> 是否显示
       */

      // bus.emit("three-scene-1-isShowSprite", "WE产品家族点位", true);

      /**
       * Interface description: 显示 或 隐藏 所有对应的dom接口
       * params1 <boolean>
       *  */
      // bus.emit("three-scene-1-isShowAllSprite", true);

      // setTimeout(() => {
      //   // bus.emit("three-scene-1-isShowAllSprite", false);
      // }, 4000);
    },
    // 相机 动画
    cameraAnima(where) {
      bus.emit("scene-1-cameraAnima", where);
    },
    // 重置 视角
    restPosition() {
      bus.emit("scene-1-reset");
    },
    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },
  },
  // 场景自带销毁
  unmounted() {
    sceneChange && sceneChange.dispose();
    sceneChange = null;
  },
};

// 导出场景 资源
export { sceneChange, scene };
</script>

<style lang="less" scoped>
.three-scene-1 {
  position: fixed;
}
#animae-width {
  animation: widths 1s ease forwards;
}
// 场景
.three-scene-1 {
  width: 100%;
  height: 100%;
}
.sprite-name-btn {
  position: absolute;
  z-index: 2;
  opacity: 0;
  width: 48px;
  height: 48px;
  background: linear-gradient(270deg, #1106dd 0%, #e52c2c 100%);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 8px 0px rgba(238, 251, 255, 0.84);
    border-radius: 19px;
    .icon {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.show-anime {
  animation: widths 1s ease forwards;
}
@keyframes widths {
  from {
    max-width: 10px;
    overflow: hidden;
  }
  to {
    max-width: 280px;
    overflow: hidden;
  }
}
.sprite-name-img {
  position: absolute;
  height: 36px;
  background: linear-gradient(270deg, #1106dd 0%, #e52c2c 100%);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;
  padding: 6px;
  // width:0;
  // height:0;
  overflow: hidden;
  .left-img {
    width: 36px;
    height: 36px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 8px 0px rgba(238, 251, 255, 0.84);
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .text {
    margin: 0 16px;
    line-height: 18px;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
  }
}

.three-scene-1 .btn {
  position: absolute;
  z-index: 2;
}

.three-scene-1 .show {
  opacity: 1 !important;
}

.three-scene-1 .none {
  opacity: 0 !important;
}

.three-scene-1 .block {
  display: block !important;
}
</style>
