<template>
  <custom-box-right>
    <img style="width:100%" v-if="isCn" :src="'./assets/images/smart1.png'" alt="">
    <img style="width:100%" v-else :src="'./assets/images/smart1-en.png'" alt="">
  </custom-box-right>
</template>

<script>

export default{
}
</script>