<template>
  <custom-box>
    <img style="width:100%" v-if="isCn" :src="'./assets/images/guang-fu2.png'" alt="">
    <img style="width:100%" v-else :src="'./assets/images/guang-fu2-en.png'" alt="">
  </custom-box>
</template>

<script>

export default{
  created(){
    window.ns=()=>this.$store.state.isCn=!this.$store.state.isCn
  }
}
</script>