<template>
  <div class="scene1">
    <scene1 @onDone="onDone"></scene1>
    <transition name="fade">
      <div
        class="boxsss"
        v-show="showUIName !== '' && !rightUI.includes(showUIName)"
      >
        <component :is="showUIName"></component>
      </div>
    </transition>

    <transition name="rightFade">
      <div
        class="boxsss-right"
        v-show="showUIName !== '' && rightUI.includes(showUIName)"
      >
        <component :is="showUIName"></component>
      </div>
    </transition>

    <LargeVideo
      v-if="isShowVideo"
      :videoSrc="video1"
      ref="videoScene1Ref"
    ></LargeVideo>
    <div class="lottie" v-if="loadingAn">
      <lottie-web></lottie-web>
    </div>
    <!-- <ControlTop></ControlTop> -->
    <!-- <CarEle></CarEle> -->
    <!-- <SmartPcs></SmartPcs> -->
    <!-- <TopJin></TopJin> -->
    <!-- <TopLight></TopLight> -->
    <!-- <TopSmart></TopSmart> -->
    <!-- <cloud></cloud> -->
    <!-- <TopSmart></TopSmart> -->
    <!-- <XuEle></XuEle> -->
    <!-- <TopVideo></TopVideo> -->
    <!-- <JieSuan></JieSuan> -->
    <!-- <JieShao></JieShao> -->
    <!-- <WeSeeds></WeSeeds> -->
  </div>
</template>
<script>
import scene1 from "./../components/three-scene-1/index.vue";
import topCbd from "./components/top-cbd.vue";
import cloud from "./components/cloud.vue";
import bus from "@/components/utils/bus.js";
import ControlTop from "./components/control-top.vue";
import WePower from "./components/we-power.vue";
import JieSuan from "./components/jie-suan.vue";
import CarEle from "./components/car-ele.vue";
import TopLight from "./components/top-light.vue";
import HuBu from "./components/hu-bu.vue";
import SmartPcs from "./components/smart-pcs.vue";
import YinJian from "./components/yin-jian.vue";
import JieShao from "./components/jie-shao.vue";
import WeSeeds from "./components/we-seeds.vue";
import HuYong from "./components/hu-yong.vue";
import TopVideo from "./components/top-video.vue";
import LargeVideo from "./components/large-video.vue";
import TopJin from "./components/top-jin.vue";
import ShuangTan from "./components/shuang-tan.vue";
import XuEle from "./components/xu-ele.vue";
import TopSmart from "./components/top-smart.vue";
import { cameraPointData } from "@/components/three-scene-1/const.js";
import { bindExpression } from "@babel/types";
import mitt from "@/lib/bus";
import GuangFu1 from "./components/guang-fu1.vue";
import GuangFu2 from "./components/guang-fu2.vue";
import GuangFu3 from "./components/guang-fu3.vue";
import GuangFu4 from "./components/guang-fu4.vue";
import GuangFu5 from "./components/guang-fu5.vue";
import GuangFu6 from "./components/guang-fu6.vue";
import GuangFu7 from "./components/guang-fu7.vue";
import GuangFu8 from "./components/guang-fu8.vue";
export default {
  components: {
    GuangFu2,
    GuangFu3,
    GuangFu4,
    GuangFu5,
    GuangFu6,
    GuangFu7,
    GuangFu8,
    scene1,
    topCbd,
    cloud,
    ControlTop,
    WePower,
    JieSuan,
    CarEle,
    TopLight,
    HuBu,
    SmartPcs,
    YinJian,
    JieShao,
    WeSeeds,
    HuYong,
    TopVideo,
    LargeVideo,
    TopJin,
    ShuangTan,
    XuEle,
    TopSmart,
    GuangFu1,
  },
  data() {
    return {
      isCloseWs: false,
      isOpenBQ: false,
      loadingAn: true,
      lastWhere: "",
      isShowVideo: false,
      // video1: require(""),
      video1: "",
      showUIName: "",
      socket: "",
      devMap: {
        openBq: {
          camMap: "three-scene-1-isShowAllSprite",
          route: null,
          UI: "keep",
        },
        allScene: {
          camMap: null,
          route: null,
          UI: "keep",
          system: "all",
        },
        closeVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "close",
          },
        },
        openDCVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/xunidc.mp4",
          },
          route: null,
        },
        openSTVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/3060st.mp4",
          },
          route: null,
        },
        openZxVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/zxtj.mp4",
          },
          route: null,
        },
        openWPVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/wepower.mp4",
          },
          route: null,
        },
        playVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "play",
          },
        },
        pauseVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "pause",
          },
        },
        openGF2Video: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/gf2.mp4",
          },
          route: null,
        },
        openGF3Video: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/gf3.mp4",
          },
          route: null,
        },
        openGF4Video: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src:process.env.VUE_APP_BASE_ASSETS+ "assets/video/gf4.mp4",
          },
          route: null,
        },
        toGF1: {
          camMap: "",
          UI: "GuangFu1",
          route: null,
        },
        toGF2: {
          camMap: "",
          UI: "GuangFu2",
          route: null,
        },
        toGF3: {
          camMap: "",
          UI: "GuangFu3",
          route: null,
        },
        toGF4: {
          camMap: "",
          UI: "GuangFu4",
          route: null,
        },
        toGF5: {
          camMap: "",
          UI: "GuangFu5",
          route: null,
        },
        toGF6: {
          camMap: "",
          UI: "GuangFu6",
          route: null,
        },
        toGF7: {
          camMap: "",
          UI: "GuangFu7",
          route: null,
        },
        toGF8: {
          camMap: "",
          UI: "GuangFu8",
          route: null,
        },
        toCBD: {
          camMap: "CBD高楼",
          UI: "GuangFu1",
          route: null,
        },
        toWEFamil: {
          camMap: "云",
          UI: "cloud",
          route: null,
        },
        toDC: {
          camMap: "星聚虚拟电厂",
          UI: "XuEle",
          route: null,
        },
        toST: { camMap: "3060双碳", UI: "ShuangTan", route: null },
        toZX: {
          camMap: "整县推进",
          UI: "TopJin",
          route: null,
        },
        toYQ: {
          camMap: "综合能源智慧园区",
          UI: "TopSmart",
          route: null,
        },
        toScene2: {
          camMap: null,
          UI: null,
          route: "scene2",
        },
        toScene3: {
          camMap: null,
          UI: null,
          route: "scene3",
        },
        toMain1: {
          camMap: "scene-1-reset",
          UI: null,
          route: null,
        },
      },
      rightUI: ["TopSmart", "TopJin"],
      whereMap: {
        云: "WE产品家族点位",
        综合能源智慧园区: "综合能源智慧园区点位",
        CBD高楼: "CBD商业区点位",
        星聚虚拟电厂: "虚拟电厂点位",
        "3060双碳": "双碳平台点位",
        整县推进: "整县推进点位",
      },
    };
  },
  methods: {
    onDone() {
      this.loadingAn = false;
      this.$emit("loadend");

      let _this = this;
      setTimeout(() => {
        mitt.on("sod", (data) => {
          // console.log(_this);
          _this.wsMessage({ data });
        });
      }, 1000);
    },
    onPlayerPlay() {
      this.$refs.largeVideo.play();
    },
    onPausePlay() {
      this.$refs.largeVideo.pause();
    },
    async handleOrder(dev) {
      let { camMap, UI, route, video, system } = this.devMap[dev];
      if (route) {
        const map = {
          scene1: "scene-1",
          scene2: "scene-2",
          scene3: "scene-3",
        };
        // location.href=location.origin+"/#/"+map[route]
        this.$router.push({ name: route });
      }
      // if (system) {
      //   if (system == "all") {
      //     let element = document.documentElement;
      //     if (element.requestFullscreen) {
      //       element.requestFullscreen();
      //     } else if (element.webkitRequestFullScreen) {
      //       element.webkitRequestFullScreen();
      //     } else if (element.mozRequestFullScreen) {
      //       element.mozRequestFullScreen();
      //     } else if (element.msRequestFullscreen) {
      //       // IE11
      //       element.msRequestFullscreen();
      //     }
      //   }
      // }
      if (camMap) {
        let where = camMap;
        if (Object.keys(cameraPointData).includes(where)) {
          bus.emit("scene-1-cameraAnima", where, 2);
          if (this.lastWhere) {
            bus.emit(
              "three-scene-1-isShowSprite",
              this.whereMap[this.lastWhere],
              false
            );
          }
          this.lastWhere = where;
          bus.emit("three-scene-1-isShowSprite", this.whereMap[where], true);
        } else {
          if (where == "three-scene-1-isShowAllSprite") {
            this.isOpenBQ = !this.isOpenBQ;
            bus.emit(where, this.isOpenBQ);
            this.lastWhere = "";
          } else {
            bus.emit(where);
          }
          if (this.lastWhere) {
            bus.emit(
              "three-scene-1-isShowSprite",
              this.whereMap[this.lastWhere],
              false
            );
          }
          if (where == "scene-1-reset" && this.isShowVideo) {
            this.isShowVideo = false;
          }
        }
      }
      if (UI) {
        if (UI !== "keep") {
          this.showUIName = UI;
        }
      } else {
        this.showUIName = "";
      }
      if (video) {
        if (video.type == "open") {
          this.video1 = video.src;
          this.isShowVideo = true;
        } else if (video.type == "close") {
          this.isShowVideo = false;
        } else if (video.type == "pause") {
          this.$refs.videoScene1Ref.pauseVideo();
        } else if (video.type == "play") {
          this.$refs.videoScene1Ref.playVideo();
        }
      }
    },
    wsMessage({ data }) {
      try {
        let message = JSON.parse(data);
        if (message.scene.includes("scene1")) {
          let { deactive } = message;
          this.handleOrder(deactive);
        }
      } catch (e) {
        console.log("错误捕获", e);
      }
    },
    wsOpen() {
      console.log("打开socket");
    },
    wsClose(e) {
      console.log("断开连接", e);
      if (!this.isCloseWs) {
        this.initWebSocket();
      }
    },
    wsError(e) {
      console.log("错误", e);
    },
  },
  unmounted() {
    console.log("mitt");
    mitt.off("sod");
  },
};
</script>
<style lang="less" scoped>
.scene1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.lottie {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
