<template>
  <custom-box>
    <img style="width:100%" v-if="isCn" :src="'./assets/images/test-1.png'" alt="">
    <img style="width:100%" v-else :src="'./assets/images/test-1-en.png'" alt="">
  </custom-box>
</template>

<script>

export default{
  
}
</script>