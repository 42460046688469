<template>
  <custom-box>
    <div class="shuang-ui"></div>
    <div class="bot-shuang">
      <div class="left-shuang"></div>
      <div class="shuang-ui-qian"></div>
    </div>
  </custom-box>
</template>

<script>
export default {};
</script>

<style>
.shuang-ui {
  margin: 64px 38px;
  width: 597px;
  height: 688px;
  background-image: url("../../assets/top/co2.png");
  background-size: 100%;
}
.bot-shuang {
  display: flex;
  align-items: center;
  margin: -48px 100px;
}
.left-shuang {
  width: 253px;
  height: 210px;
  background-image: url("../../assets/top/bot-shuang.png");
  background-size: 100%;
}
.shuang-ui-qian {
  margin-left: 40px;
  width: 194px;
  height: 98px;
  background-image: url("../../assets/top/tiyan.png");
  background-size: 100%;
}
</style>
