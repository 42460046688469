<style lang="less" scoped>
.build {
  width: 576px;
  height: 796px;
  background-image: url("../../assets/top/jianzhu.png");
  background-size: 100%;
  margin: 64px 48px;
}
</style>
<template>
  <custom-box><div class="build"></div></custom-box>
</template>
<script>
export default {};
</script>
